import React from 'react';
import { motion } from 'framer-motion';
import { FaLock, FaChartLine, FaUsers, FaGlobe, FaHandHoldingUsd, FaSeedling, FaHiking } from 'react-icons/fa'; // More icons for variety

const FeaturesSection: React.FC = () => {
  const features = [
    {
      icon: FaLock,
      title: 'Secure Transactions',
      description: 'Leveraging advanced cryptography, $TRAIL ensures the integrity and safety of every transaction on the blockchain.',
    },
    {
      icon: FaChartLine,
      title: 'Deflationary Tokenomics',
      description: 'A built-in burn mechanism gradually reduces the total supply of $TRAIL, potentially increasing its value over time.',
    },
    {
      icon: FaUsers,
      title: 'Decentralized Governance',
      description: '$TRAIL holders actively participate in shaping the project\'s future through decentralized governance proposals and voting mechanisms.',
    },
    {
      icon: FaGlobe,
      title: 'Global Community',
      description: 'Trail enthusiasts, environmentalists, and blockchain advocates from around the world unite to create a borderless community dedicated to trail preservation.',
    },
    {
      icon: FaSeedling, // New Icon
      title: 'Environmental Impact',
      description: 'Trailcoin initiatives actively support environmental sustainability and conservation efforts, ensuring a positive impact on the planet.',
    },
    {
      icon: FaHiking, // New Icon
      title: 'Gamified Exploration',
      description: 'Discover new trails, complete challenges, and earn rewards for your outdoor adventures, making trail maintenance and exploration fun and engaging.',
    },
  ];

  return (
    <section className="features bg-gradient-to-br from-purple-900 via-blue-900 to-indigo-900 py-20 md:py-32">
      <div className="container mx-auto px-4 md:px-8 lg:px-16">
        <motion.h2
          className="text-4xl md:text-5xl lg:text-6xl font-extrabold mb-12 text-center text-white tracking-tight"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          Why Trailcoin?
        </motion.h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 md:gap-12">
          {features.map((feature, index) => (
            <motion.div
              key={index}
              className="feature-card bg-gray-800/70 backdrop-blur-lg rounded-xl shadow-lg p-8 text-center transform hover:scale-105 transition-transform"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.2 * index }}
            >
              <div className="flex justify-center items-center text-blue-400 text-5xl mb-4">
                <feature.icon />
              </div>
              <h3 className="text-xl md:text-2xl font-semibold mb-3 text-white">{feature.title}</h3>
              <p className="text-gray-200">{feature.description}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FeaturesSection;

import React from 'react';
import { motion } from 'framer-motion';
import { FaLeaf, FaHiking, FaMapMarkedAlt, FaDonate, FaRegLightbulb, FaTree } from 'react-icons/fa'; // Trail-specific icons

const useCases = [
  {
    icon: FaLeaf,
    title: 'Trail Maintenance & Enhancement',
    description: '$TRAIL incentivizes users to contribute to trail maintenance, reporting issues, clearing debris, and participating in restoration projects. This ensures trails remain safe and enjoyable for everyone.'
  },
  {
    icon: FaHiking,
    title: 'Decentralized Trail Governance',
    description: 'The Trail Builders Network, a decentralized autonomous organization (DAO), allows $TRAIL holders to propose and vote on new trail initiatives, upgrades, and fund allocation, fostering community-driven decision-making.'
  },
  {
    icon: FaMapMarkedAlt,
    title: 'Gamified Exploration & Rewards',
    description: '$TRAIL rewards users for exploring new trails, sharing their experiences, and contributing valuable information to the community. This gamification element encourages outdoor activity and discovery.'
  },
  {
    icon: FaDonate,
    title: 'Fundraising & Crowdfunding for Trails',
    description: 'Leverage the power of the Trailcoin community to raise funds for critical trail projects, from small repairs to large-scale development initiatives. $TRAIL provides a transparent and efficient way to gather support and resources.'
  },
  {
    icon: FaRegLightbulb,
    title: 'Education & Awareness',
    description: 'Create and share educational content about trail etiquette, environmental conservation, and the importance of sustainable practices, fostering a community of responsible trail users.'
  },
  {
    icon: FaTree,
    title: 'Carbon Offsets & Sustainability',
    description: 'Trailcoin initiatives can be integrated with carbon offset programs, enabling users to contribute to reforestation efforts and offset their environmental impact.'
  },
];

const UseCasesSection: React.FC = () => (
  <section className="bg-gradient-to-br from-purple-900 via-blue-900 to-indigo-900 py-20 md:py-32 text-white">
    <div className="container mx-auto px-4 md:px-8 lg:px-16 text-center">
      <motion.h2
        className="text-4xl md:text-5xl lg:text-6xl font-extrabold mb-12 text-white tracking-tight"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
      >
        Empowering Trailblazers with $TRAIL
      </motion.h2>
      
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 md:gap-12">
        {useCases.map((useCase, index) => (
          <motion.div
            key={index}
            className="bg-gray-800/70 backdrop-blur-lg rounded-xl shadow-lg p-8 text-center transform transition-transform duration-300 hover:scale-105"
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.6, delay: 0.1 * index }}
          >
            <div className="flex justify-center items-center h-20 w-20 bg-gradient-to-br from-green-400 to-teal-500 rounded-full mx-auto mb-6">
              <useCase.icon className="text-white text-3xl" />
            </div>
            <h3 className="text-2xl md:text-2xl font-semibold mb-3">{useCase.title}</h3>
            <p className="text-gray-300 text-base leading-relaxed">{useCase.description}</p>
          </motion.div>
        ))}
      </div>
    </div>
  </section>
);

export default UseCasesSection;

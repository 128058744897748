import React, { useState } from 'react';
import { FaShieldAlt, FaBug, FaLock, FaHandshake, FaUserShield, FaFileCode } from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';

const SecuritySection: React.FC = () => {
  const [isHovered, setIsHovered] = useState<number | null>(null);

  const cardVariants = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0, transition: { duration: 0.6, ease: "easeOut" } },
    exit: { opacity: 0, y: 20, transition: { duration: 0.3, ease: "easeIn" } }
  };

  const gradientVariants = {
    hover: { opacity: 0.25, transition: { duration: 0.3 } },
    rest: { opacity: 0 }
  };

  return (
    <section className="security bg-gradient-to-br from-purple-900 via-blue-900 to-indigo-900 py-20 md:py-32">
    <div className="container mx-auto px-4 md:px-8 lg:px-16">
      <motion.h2
        className="text-4xl md:text-5xl font-extrabold mb-12 text-white text-center tracking-tight font-display"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0, transition: { delay: 0.3, duration: 1 } }}
      >
        Security & Trust: The Foundations of Trailcoin
      </motion.h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
        <AnimatePresence>
          {[FaShieldAlt, FaBug, FaLock, FaHandshake, FaUserShield, FaFileCode].map((Icon, index) => (
            <motion.div
              key={index}
              className={`bg-gray-800/70 backdrop-blur-lg rounded-xl shadow-lg p-10 text-center relative overflow-hidden cursor-pointer transition-transform transform hover:scale-105`}
              variants={cardVariants}
              initial="initial"
              animate="animate"
              exit="exit"
              onHoverStart={() => setIsHovered(index)}
              onHoverEnd={() => setIsHovered(null)}
            >
              {/* Background gradient animation */}
              <motion.div
                className="absolute inset-0 bg-gradient-to-r from-green-400 to-teal-500"
                variants={gradientVariants}
                animate={isHovered === index ? "hover" : "rest"}
              />
              <Icon className="text-green-500 text-6xl mb-6 mx-auto" />
              <h3 className="text-2xl md:text-3xl font-semibold mb-3 text-white font-headline">
                {
                  [
                    'Rigorous Audits', 
                    'Bug Bounty Program', 
                    'Industry Best Practices', 
                    'Community Governance',
                    'User Fund Protection',
                    'Open Source Code' // New Feature
                  ][index]
                }
              </h3>
              <p className="text-gray-300 font-body leading-relaxed">
                {
                  [
                    'Our smart contracts undergo rigorous third-party audits to ensure the highest level of security and reliability.',
                    'We actively encourage the community to identify and report vulnerabilities through our generous bug bounty program.',
                    'We adhere to the strictest industry standards and best practices, utilizing multi-sig wallets and regular code reviews.',
                    'The Trail Builders Network ensures transparency and accountability through community-led decision-making.',
                    'User funds are protected through secure smart contracts, regular audits, and transparent governance processes.',
                    'Our codebase is open source, allowing for community scrutiny and ensuring transparency.' // New Description
                  ][index]
                }
              </p>
            </motion.div>
          ))}
        </AnimatePresence>
      </div>
    </div>
  </section>
  );
};

export default SecuritySection;

// BinanceSVG.js
import React from 'react';

const OKXSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 -0.001 157.427 44.186">
    <path fill="#fff"
     d="M46.17 0H1.05C.77 0 .503.103.306.288A.951.951 0 0 0 0 .982v42.22c0 .26.11.51.307.694s.464.288.742.288H46.17c.279 0 .545-.104.743-.288a.952.952 0 0 0 .307-.694V.982a.95.95 0 0 0-.307-.694A1.088 1.088 0 0 0 46.17 0zM31.48 28.474c0 .26-.11.51-.308.694a1.087 1.087 0 0 1-.741.288H16.789c-.278 0-.545-.104-.742-.288a.951.951 0 0 1-.307-.694V15.71c0-.26.11-.51.307-.694.197-.185.464-.288.742-.288h13.642c.278 0 .545.103.741.288a.95.95 0 0 1 .308.694zM140.63 14.73h-13.642c-.58 0-1.05.44-1.05.982v12.764c0 .542.47.982 1.05.982h13.641c.58 0 1.05-.44 1.05-.982V15.712c0-.543-.47-.982-1.05-.982zM124.893 0h-13.641c-.58 0-1.05.44-1.05.983v12.764c0 .542.47.982 1.05.982h13.64c.58 0 1.05-.44 1.05-.982V.983c0-.543-.47-.982-1.05-.982zm31.485 0h-13.642c-.579 0-1.049.44-1.049.983v12.764c0 .542.47.982 1.05.982h13.64c.58 0 1.05-.44 1.05-.982V.983c0-.543-.47-.982-1.05-.982zm-31.485 29.457h-13.641c-.58 0-1.05.44-1.05.982v12.765c0 .542.47.981 1.05.981h13.64c.58 0 1.05-.44 1.05-.981V30.439c0-.542-.47-.982-1.05-.982zm31.485 0h-13.642c-.579 0-1.049.44-1.049.982v12.765c0 .542.47.981 1.05.981h13.64c.58 0 1.05-.44 1.05-.981V30.439c0-.542-.47-.982-1.05-.982zM101.264 0h-13.64c-.58 0-1.05.44-1.05.983v12.764c0 .542.47.982 1.05.982h13.64c.58 0 1.05-.44 1.05-.982V.983c0-.543-.47-.982-1.05-.982zm0 29.457h-13.64c-.58 0-1.05.44-1.05.982v12.765c0 .542.47.981 1.05.981h13.64c.58 0 1.05-.44 1.05-.981V30.439c0-.542-.47-.982-1.05-.982zM86.57 15.699c0-.26-.112-.51-.308-.695a1.087 1.087 0 0 0-.742-.287H70.829V.982a.95.95 0 0 0-.307-.694A1.087 1.087 0 0 0 69.78 0H56.139c-.279 0-.546.103-.743.288a.951.951 0 0 0-.307.694V43.18c0 .26.11.51.307.694s.464.288.743.288h13.64c.279 0 .546-.104.743-.288s.307-.434.307-.694V29.445h14.69c.279 0 .546-.104.743-.288a.952.952 0 0 0 .307-.694z">
      </path>
      </svg>
);
export default OKXSvg;

import React, { useEffect, useState } from 'react';
import AboutComponent from '../components/About';
import FeaturesComponents from '../components/Features';
import RoadmapComponents from '../components/RoadmapSection';
import TokenomicsComponents from '../components/Tokenomics';
import UseCasesSection from '../components/UseCasesSection';
import SecuritySection from '../components/SecuritySection';
import Footer from '../components/Footer';
import HeroSection from '../components/Hero';


const Features: React.FC = () => {

  return (
    <>
             
            <FeaturesComponents />
            <UseCasesSection />
            <SecuritySection /> 
            <Footer />
    </>
  );
};

export default Features;

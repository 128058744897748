import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import ProductFeature from './ProductFeature';
import TokenomicsSection from './TokenomicsSection';
import TokenomicsChart from './TokenomicsChart';

const Tokenomics = () => {
    const allocationData = [
        { 
            title: 'Ecosystem', 
            percentage: 60, 
            color: '#0088FE', 
            description: 'Supporting trail maintenance, development, and community initiatives.' 
        },
        { 
            title: 'Liquidity', 
            percentage: 15, 
            color: '#00C49F', 
            description: 'Ensuring a healthy market for $TRAIL on decentralized exchanges.' 
        },
        { 
            title: 'Marketing & Partnerships', 
            percentage: 10, 
            color: '#FFBB28', 
            description: 'Expanding the Trailcoin community and building strategic alliances.' 
        },
        { 
            title: 'Development & Operations', 
            percentage: 10, 
            color: '#FF8042', 
            description: 'Continuously enhancing the platform and ensuring its long-term sustainability.' 
        },
        { 
            title: 'Reserve Fund', 
            percentage: 5, 
            color: '#AF19FF', 
            description: 'Providing financial stability and supporting future growth initiatives.' 
        },
    ];

    const [activeIndex, setActiveIndex] = useState<number | null>(null);

    const handleMouseEnter = (index: number) => {
        setActiveIndex(index);
    };

    const handleMouseLeave = () => {
        setActiveIndex(null);
    };

    const pieChartData = allocationData.map(item => ({
        name: item.title,
        value: item.percentage, 
    }));

    return (
        <div className="bg-gradient-to-br from-blue-900 via-purple-800 to-teal-700 min-h-screen bg-gray-900 text-white p-6 py-20 md:py-32">
             {/* Title */}
             <motion.h2
                            className="text-4xl md:text-5xl lg:text-6xl font-extrabold mb-12 text-center text-white tracking-tight mb-8"
                            initial={{ opacity: 0, y: -20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 1 }}
                        >
                            <span className="bg-clip-text text-transparent bg-gradient-to-r  from-white to-cyan-500">
                                Tokenomics
                            </span> 
                             <br/>
                              That Fuel a Sustainable Ecosystem
                        </motion.h2>
            <div className="max-w-7xl mx-auto grid grid-cols-1 lg:grid-cols-2 gap-8">
                
                {/* Left Section: Products/Features */}
                <div className="space-y-6">
          <div>
            <h2 className="text-3xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r  from-white to-gray-500  mb-4">Upcoming Web3 Products</h2>
            <div className="grid grid-cols-1 md:grid-cols-1 gap-4"> {/* Grid for product features */}
              <ProductFeature title="TrailSwap" description="A decentralized exchange for trading tokens." />
              <ProductFeature title="TrailStake" description="A staking platform to earn rewards." />
              <ProductFeature title="TrailWallet" description="A secure wallet for managing Trailcoin." />
              <ProductFeature title="Telegram Mini Apps" description="Integrate Trailcoin features directly into Telegram." /> 

            </div>
          </div>
        </div>

                <div className="space-y-6">
                    <TokenomicsSection />
                </div>
            </div>
        </div>
    );
};

export default Tokenomics;

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Features from './pages/Features';
import Tokenomics from './pages/Tokenomics';
import RoadmapPage from './pages/Roadmap';
import NoiticationComp from './components/NotificationComponent';

const App: React.FC = () => {
    return (
        <Router>
            <div className="App">
            <NoiticationComp/>
                <Header />
                <main>
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/about" element={<About />} />
                        <Route path="/features" element={<Features />} />
                        <Route path="/tokenomics" element={<Tokenomics />} />
                        <Route path="/roadmap" element={<RoadmapPage />} />
                        <Route path="/contact" element={<Contact />} />
                    </Routes>
                </main>
            </div>
        </Router>
    );
};

export default App;

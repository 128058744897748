import { ConnectWallet, useActiveClaimConditionForWallet, useAddress, useClaimConditions, useClaimerProofs, useClaimIneligibilityReasons, useContract, useContractMetadata, useContractRead, useTokenBalance, useTokenSupply, Web3Button } from '@thirdweb-dev/react';
import { BigNumber, ethers, utils } from 'ethers';
import React, { useEffect, useMemo, useState } from 'react'
import { submitWalletcontracts, tokendropcontract } from '../const/ContractAddress';
import { parseIneligibility } from '../utils/parseIneligibility';
import Countdown from 'react-countdown';
import { trailcoin, trailrobot } from '../images';
import { motion, AnimatePresence } from 'framer-motion';
import Swal from 'sweetalert2'; 
import PriceFeedV1 from './PriceFeedV1';



const BuyCard = () => {
    const address = useAddress();
    const { contract: tokenContract, isLoading: loadingToken } = useContract(tokendropcontract, "token-drop");
    const { data: tokenBalance, isLoading: loadingTokenBalance } = useTokenBalance(tokenContract, address);
  
    const {
      contract
    } = useContract(submitWalletcontracts);
  
  
    const manualUsdRate = 0.11;
    const [usdPrice, setUsdPrice] = useState<number | null>(null);
    const [quantity, setQuantity] = useState(10);
    const { data: contractMetadata } = useContractMetadata(tokenContract);
    const claimedSupply = useTokenSupply(tokenContract);
    const { data: tokenSupply, isLoading: loadingTokenSupply } = useTokenSupply(tokenContract);
    const maxCap = 10_000_000; // Or fetch it from contract metadata if available
  
  
    const [buyAmount, setBuyAmount] = useState(0.11);
    const [message, setMessage] = useState<string>("");
  
    const { 
      data: totalCoffees, 
      isLoading: loadingTotalCoffee 
    } = useContractRead(contract, "getTotalCoffee");
  
    const { 
      data: recentCoffee, 
      isLoading: loadingRecentCoffee 
    } = useContractRead(contract, "getAllCoffee");
  
    const convertDate = (timestamp: bigint) => {
      const timestampNumber = Number(timestamp);
      return new Date(timestampNumber * 1000).toLocaleString();
  };
   
    const [tasksCompleted, setTasksCompleted] = useState({
      telegramChannel: false,
      telegramGroup: false,
      // followOnX: false,
      likeOnFacebook: false,
      followOnCMC: false,
      claimTokens: false,
    });
  
    const handleTaskClick = (taskName: string) => {
      setTasksCompleted((prevState) => ({
        ...prevState,
        [taskName]: true,
      }));
    };
  
    const allTasksCompleted = Object.values(tasksCompleted).every(Boolean);
  
  
    const claimConditions = useClaimConditions(tokenContract);
    const activeClaimCondition = useActiveClaimConditionForWallet(tokenContract, address);
    const claimerProofs = useClaimerProofs(tokenContract, address || "");
    const [quantityError, setQuantityError] = useState<string | null>(null);
    const claimIneligibilityReasons = useClaimIneligibilityReasons(tokenContract, {
      quantity,
      walletAddress: address || "",
    });

    // Input validation logic
  const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value);
    if (value > maxClaimable) {
      setQuantity(maxClaimable);
      setQuantityError("Cannot exceed maximum claimable amount");
    } else if (value < 1) {
      setQuantity(1);
      setQuantityError("Quantity must be at least 1");
    } else {
      setQuantity(value);
      setQuantityError(null);
    }
  };
  
    const totalAvailableSupply = useMemo(() => {
      try {
        return BigNumber.from(activeClaimCondition.data?.availableSupply || 0);
      } catch {
        return BigNumber.from(1_000_000_000);
      }
    }, [activeClaimCondition.data?.availableSupply]);
  
    const numberClaimed = useMemo(() => {
      return BigNumber.from(claimedSupply.data?.value || 0).toString();
    }, [claimedSupply]);
  
    const numberTotal = useMemo(() => {
      const n = totalAvailableSupply.add(BigNumber.from(claimedSupply.data?.value || 0));
      if (n.gte(1_000_000_000)) {
        return "";
      }
      return n.toString();
    }, [totalAvailableSupply, claimedSupply]);
  
    const priceToMint = useMemo(() => {
      if (quantity) {
        const bnPrice = BigNumber.from(activeClaimCondition.data?.currencyMetadata.value || 0);
        return `${utils.formatUnits(bnPrice.mul(quantity).toString(), activeClaimCondition.data?.currencyMetadata.decimals || 18)} ${activeClaimCondition.data?.currencyMetadata.symbol}`;
      }
    }, [
      activeClaimCondition.data?.currencyMetadata.decimals,
      activeClaimCondition.data?.currencyMetadata.symbol,
      activeClaimCondition.data?.currencyMetadata.value,
      quantity,
    ]);
  
    const maxClaimable = useMemo(() => {
      let bnMaxClaimable;
      try {
        bnMaxClaimable = BigNumber.from(activeClaimCondition.data?.maxClaimableSupply || 0);
      } catch (e) {
        bnMaxClaimable = BigNumber.from(1_000_000_000);
      }
  
      let perTransactionClaimable;
      try {
        perTransactionClaimable = BigNumber.from(activeClaimCondition.data?.maxClaimablePerWallet || 0);
      } catch (e) {
        perTransactionClaimable = BigNumber.from(1_000_000_000);
      }
  
      if (perTransactionClaimable.lte(bnMaxClaimable)) {
        bnMaxClaimable = perTransactionClaimable;
      }
  
      const snapshotClaimable = claimerProofs.data?.maxClaimable;
  
      if (snapshotClaimable) {
        if (snapshotClaimable === "0") {
          // allowed unlimited for the snapshot
          bnMaxClaimable = BigNumber.from(1_000_000_000);
        } else {
          try {
            bnMaxClaimable = BigNumber.from(snapshotClaimable);
          } catch (e) {
            // fall back to default case
          }
        }
      }
  
      let max;
      if (totalAvailableSupply.lt(bnMaxClaimable)) {
        max = totalAvailableSupply;
      } else {
        max = bnMaxClaimable;
      }
  
      if (max.gte(1_000_000_000)) {
        return 1_000_000_000;
      }
      return max.toNumber();
    }, [
      claimerProofs.data?.maxClaimable,
      totalAvailableSupply,
      activeClaimCondition.data?.maxClaimableSupply,
      activeClaimCondition.data?.maxClaimablePerWallet,
    ]);
  
    const isSoldOut = useMemo(() => {
      try {
        return (
          (activeClaimCondition.isSuccess &&
            BigNumber.from(activeClaimCondition.data?.availableSupply || 0).lte(0)) ||
          numberClaimed === numberTotal
        );
      } catch (e) {
        return false;
      }
    }, [
      activeClaimCondition.data?.availableSupply,
      activeClaimCondition.isSuccess,
      numberClaimed,
      numberTotal,
    ]);
  
    const canClaim = useMemo(() => {
      return (
        activeClaimCondition.isSuccess &&
        claimIneligibilityReasons.isSuccess &&
        claimIneligibilityReasons.data?.length === 0 &&
        !isSoldOut
      );
    }, [
      activeClaimCondition.isSuccess,
      claimIneligibilityReasons.data?.length,
      claimIneligibilityReasons.isSuccess,
      isSoldOut,
    ]);
  
    const isLoading = useMemo(() => {
      return activeClaimCondition.isLoading || !tokenContract;
    }, [activeClaimCondition.isLoading, tokenContract]);
  
    const buttonLoading = useMemo(
      () => isLoading || claimIneligibilityReasons.isLoading,
      [claimIneligibilityReasons.isLoading, isLoading]
    );
    
    const buttonText = useMemo(() => {
      if (isSoldOut) {
        return "Checking Status";
      }
  
      if (canClaim) {
        const pricePerToken = BigNumber.from(activeClaimCondition.data?.currencyMetadata.value || 0);
        if (pricePerToken.eq(0)) {
          return "Claim (Free)";
        }
        return `Claim (${priceToMint})`;
      }
      if (claimIneligibilityReasons.data?.length) {
        return parseIneligibility(claimIneligibilityReasons.data, quantity);
      }
      if (buttonLoading) {
        return "Checking eligibility...";
      }
  
      return "Claiming not available";
    }, [
      isSoldOut,
      canClaim,
      claimIneligibilityReasons.data,
      buttonLoading,
      activeClaimCondition.data?.currencyMetadata.value,
      priceToMint,
      quantity,
    ]);
  
    useEffect(() => {
      // Calculate the USD price based on the manual rate
      if (tokenBalance?.value !== undefined) {
        const usdPrice = parseFloat(ethers.utils.formatEther(tokenBalance.value)) * manualUsdRate;
        setUsdPrice(usdPrice);
      }
    }, [tokenBalance, manualUsdRate]);

    const idoEndDate = new Date('2024-08-30T12:00:00Z').getTime();
    const [counter, setCounter] = useState(0); // State for seconds countdown
  
    useEffect(() => {
      const timer = setInterval(() => {
        const now = new Date().getTime();
        const distance = idoEndDate - now;
  
        if (distance > 0) {
          const seconds = Math.floor((distance % (1000 * 60)) / 1000);
          setCounter(seconds);
        } else {
          clearInterval(timer); // Stop the timer when the countdown ends
        }
      }, 1000); // Update every second
  
      return () => clearInterval(timer); // Cleanup on unmount
    }, [idoEndDate]); 

    const handleScaleClick = (percentage: number) => {
        const newQuantity = Math.round(maxClaimable * (percentage / 500));
        setQuantity(newQuantity);
      };
      
      const progressPercentage = useMemo(() => {
        if (loadingTokenSupply || !tokenSupply?.value) return 0;
    
        const totalMinted = BigNumber.from(tokenSupply.value);
        const fixedCap = BigNumber.from(maxCap); 
    
        const progressBigNumber = totalMinted.mul(100).div(fixedCap);
    
        return parseFloat(ethers.utils.formatUnits(progressBigNumber, 0)); 
      }, [loadingTokenSupply, tokenSupply, maxCap]); // Include maxCap in the dependency array
    
      

  const tokenBalanceDisplay = tokenBalance?.displayValue || "0";
  const totalSupplyDisplay = tokenSupply?.displayValue || "0";
  const [currentProgress, setCurrentProgress] = useState(0); // State to store the current progress percentage

  useEffect(() => {
    if (!loadingTokenSupply && tokenSupply?.displayValue) {
      const calculatedPercentage = ((parseFloat(tokenSupply.displayValue) / maxCap) * 100).toFixed(2);
      setCurrentProgress(parseFloat(calculatedPercentage)); // Update the state
    }
  }, [loadingTokenSupply, tokenSupply, maxCap]); // Recalculate when these dependencies change

      
    
    const renderer = ({ days, hours, minutes, seconds, completed }: any) => {
        if (completed) {
          return <span className="text-red-500">IDO Ended</span>;
        } else {
          return (
            <div className="grid grid-flow-col gap-5 text-center auto-cols-max">
              <div className="flex flex-col p-2 bg-neutral rounded-box text-neutral-content">
                <span className="countdown font-bold text-4xl">
                  <span style={{ "--value": days } as React.CSSProperties}></span>
                </span>
                days
              </div>
              <div className="flex flex-col p-2 bg-neutral rounded-box text-neutral-content">
                <span className="countdown font-bold text-4xl">
                  <span style={{ "--value": hours } as React.CSSProperties}></span>
                </span>
                hours
              </div>
              <div className="flex flex-col p-2 bg-neutral rounded-box text-neutral-content">
                <span className="countdown font-bold text-4xl">
                  <span style={{ "--value": minutes } as React.CSSProperties}></span>
                </span>
                min
              </div>
              <div className="flex flex-col p-2 bg-neutral rounded-box text-neutral-content">
                <span className="countdown font-bold text-4xl">
                  <span style={{ "--value": seconds } as React.CSSProperties}></span>
                </span>
                sec
              </div>
            </div>
          );
        }
      };
      
      
  return (
    <motion.div
      className="md:w-1/2 mt-12 md:mt-0 flex flex-col items-center md:items-end"
      initial={{ opacity: 0, scale: 0.8 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ delay: 1.1, duration: 0.8 }}
    >
      <div 
        className="flex flex-col items-center p-6 bg-gradient-to-r from-gray-900 to-black rounded-lg shadow-xl w-full md:w-3/4 text-white "
        
      >
      <div className="flex items-center justify-between w-full mb-4">
        <div className="flex items-center justify-center space-x-2">
          <img src={trailcoin} alt="Trail Builders Network" className="h-10 w-10 rounded-full" />
          <div>
            <h3 className="text-lg text-left font-semibold">Trailcoin (TRL)</h3>
            <p className="text-xs text-left text-gray-400">
              {!loadingTokenSupply !== undefined ? (
                               <span className="text-xs text-gray-400 font-semibold">{tokenSupply?.displayValue ? new Intl.NumberFormat('en-US', { style: 'decimal' }).format(parseFloat(tokenSupply.displayValue)) : 'Loading'} {tokenSupply?.symbol}
                               </span>
                    ) : (
                                <span className="text-xl text-black font-semibold">Loading...</span>        
                      )}
                      
                      </p>


          </div>
        </div>
        <div className="ml-auto flex items-center">
          {address ? (
           <div className="presaleAuth">
          <ConnectWallet/>
            </div>          
          ) : (
            <div className="presaleAuth">
                <a href="https://bscscan.com/token/0xF1075300d3e57e069EB7ce2E9802Cba99a1c86AD#code" target="_blank" className="verified"><i className="ri-shield-user-fill"></i> Verified</a>
                </div>
          )}
        </div>
      </div>

      {address ? (

        <div className="text-center">
        <PriceFeedV1/>
 </div>

        ) : (
      <div className="text-center mb-4">
        <header>
        <h2 className='font-bold text-3xl mt-4 mb-4'>Buy TrailCoin and Secure Your Whitelist Spot!</h2>
       <center><img src={trailrobot} alt="Trail Builders Network" className="mt-4 mb-4 robot rounded-full" /></center>
        <p>Get your TrailCoins easily with our OTC option and secure your spot!</p>
      </header>
      <br/>
      <PriceFeedV1/>
      </div>
      )}
     

    <div className="w-full flex flex-col items-center">
      {address ? (
    <div className="mt-6 w-full flex flex-col items-center">
     <div>
          {isLoading ? (
          <p className="text-center">Loading Please Wait...</p>
          ) : (
            <>
            <AnimatePresence> {/* Wrap with AnimatePresence */}
            <motion.div
      key={tokenBalance?.value ? tokenBalance.value.toString() : undefined} // Convert to string if exists
      initial={{ opacity: 0, scale: 0.95 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.9 }}
      className="flex flex-col items-center mb-4 p-4 rounded-lg shadow-md"
      style={{
        background: 'linear-gradient(135deg, #a855f7, #ec4899)', 
        borderRadius: '0.75rem',
        border: '1px solid #ddd',
      }}
    >
                <h3 className="text-lg font-semibold text-white mb-2">Your Trail Coin Balance</h3>
                <motion.p 
                  className="text-3xl font-bold text-white" 
                  initial={{ y: 10, opacity: 0 }} // Initial animation state
                  animate={{ y: 0, opacity: 1 }} // Final animation state
                  transition={{ duration: 0.5 }} // Animation duration
                >
                  {tokenBalance?.value
                    ? `${parseFloat(
                        ethers.utils.formatUnits(
                          BigNumber.from(tokenBalance.value),
                          tokenBalance.decimals ?? 18
                        )
                      ).toLocaleString()} ${tokenBalance?.symbol ?? ""}`
                    : "0"}
                </motion.p>
                <p className="text-gray-200 mt-1 text-sm"> 
                  ≈ {usdPrice?.toFixed(2)} USDT 
                </p>
              </motion.div>
            </AnimatePresence>
          </>
      )}

         
<div className="flex flex-col items-center mb-4"> {/* Input field container */}
                  <label htmlFor="quantityInput" className="text-sm mb-1">TrailCoin Quantity</label> {/* Label for input */}
                  <input
                    id="quantityInput"
                    type="number"
                    placeholder="Enter quantity"
                    onChange={handleQuantityChange}
                    value={quantity}
                    className={`presaleInput ${quantityError ? 'border-red-500' : ''}`} // Visual feedback for errors
                    aria-label="TrailCoin Quantity" // Accessibility for screen readers
                  />
                  {quantityError && <p className="text-red-500 text-xs mt-1">{quantityError}</p>} {/* Error message */}
                </div>

      <div className="balanceScale">
        <p className="balanceScaleItem" id="scale-10" onClick={() => handleScaleClick(0.00001)}>10%</p>
        <p className="balanceScaleItem" id="scale-25" onClick={() => handleScaleClick(0.00025)}>25%</p>
        <p className="balanceScaleItem" id="scale-50" onClick={() => handleScaleClick(0.00050)}>50%</p>
        <p className="balanceScaleItem" id="scale-75" onClick={() => handleScaleClick(0.00075)}>75%</p>
        <p className="balanceScaleItem" id="scale-100" onClick={() => handleScaleClick(0.00100)}>100%</p>
      </div>
      <div className='mt-5 flex flex-col item-center justify-center'> 
      <Web3Button
            theme="dark"
            contractAddress={tokendropcontract}
            action={(contract) => contract.erc20.claim(quantity)}
            onSuccess={() => Swal.fire("Claim Successfully")}
            onError={(err) => Swal.fire("No Gas Fees Try Again")}
            style={{ 
              color: "white", 
              background: 'linear-gradient(135deg, #007bff, #0056b3)', // Professional blue gradient
              borderRadius: '0.375rem', // Slightly less rounded corners
              transition: 'background-color 0.2s ease', // Subtle transition
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Subtle shadow
            }}          
            className="hover:bg-gradient-to-r from-blue-600 to-blue-800" // Hover effect
            >
            {buttonText}
          </Web3Button>
        </div>
          
          {(claimConditions.data &&
            claimConditions.data.length > 0 &&
            activeClaimCondition.isError) ||
          (activeClaimCondition.data &&
          activeClaimCondition.data.startTime   
> new Date() && (
            <p className="text-black text-center">Token Sale is starting soon. Please check back later.</p>
          ))}

          {claimConditions.data?.length === 0 ||
            (claimConditions.data?.every((cc) => cc.maxClaimableSupply   
=== "0") && (
                <p className="text-black text-center">
              This Token Sale is not ready to be minted yet. (No claim condition set)
                </p>
                ))}
        </div>

    </div>
  ) : (
    <div className="mt-2 w-full flex flex-col items-center">
<ConnectWallet
        theme={"dark"}
        className="lg:hidden"
        btnTitle={"Secure my whitelist spot"}
        style={{ 
          color: "white", 
          background: 'linear-gradient(135deg, #007bff, #0056b3)', // Professional blue gradient
          borderRadius: '2.375rem', // Slightly less rounded corners
          transition: 'background-color 0.2s ease', // Subtle transition
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Subtle shadow
        }}
        />
              
    </div>
  )}
      </div>
      </div>
  </motion.div>
  )
}

export default BuyCard
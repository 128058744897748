// Footer.tsx
import React, { useState } from 'react';
import { FaFacebook, FaTwitter, FaInstagram, FaTelegram, FaYoutube, FaMedium } from 'react-icons/fa'; // Replace with your preferred social media icons
import { Link } from 'react-router-dom';
import { trailcoin } from '../images';
import { SiCoinmarketcap } from 'react-icons/si';

const Footer: React.FC = () => {
  const [email, setEmail] = useState('');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      // Create a Blob containing the email data
      const blob = new Blob([email + '\n'], { type: 'text/plain' });

      // Create a temporary URL for the Blob
      const url = window.URL.createObjectURL(blob);

      // Create a hidden anchor element to trigger the download
      const a = document.createElement('a');   

      a.href = url;
      a.download = 'emails.txt'; // Set the filename
      document.body.appendChild(a);
      a.click();

      // Clean up
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);   


      console.log('Email saved to file!');
      setEmail(''); // Clear the input field

    } catch (error) {
      console.error('Error saving email:', error);
      // Handle the error appropriately (e.g., show an error message to the user)
    }
  };
  return (
    <footer className="bg-gray-900 text-gray-300 py-8">
      <div className="container mx-auto px-4 md:px-8 lg:px-16">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">

          {/* Logo and Copyright */}
          <div className="md:text-left">
            {/* Replace 'your-logo.png' with your actual logo */}
            <Link
          to="/"
          className="text-2xl sm:text-3xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-green-400 to-teal-500 hover:opacity-90 transition-opacity flex gap-2"
        >
          <img src={trailcoin} alt="Trail Builders Network" className="h-10 w-10 rounded-full" />
          Trailcoin
        </Link>
        <p className='text-xs mt-4'>Decentralize your life, grow your wealth, and make a positive impact. Empower yourself in the Web3 revolution.</p>
        <p className="mt-4 text-xs">&copy; {new Date().getFullYear()} Trail Builders Network</p>
          </div>

          {/* Newsletter Signup */}
 
      <div className="md:text-center">
      </div>

          {/* DesignCoin and Social Media */}
          <div className="md:text-right">
            <h3 className="text-lg font-semibold mb-4">Join Trail Builders Network</h3>
            {/* DesignCoin Integration (placeholder - replace with actual implementation) */}
            <p className='text-xs'>For investment inquiries, please  <a href="mailto:ir@trailbuildersnetwork.store" className='text-blue'><u>reach out</u></a> to us. For community requests, please reach out to us <a href="mailto:info@trailbuildersnetwork.store" className='text-blue'><u>here</u></a>.</p>

            <div className="flex justify-end mt-4 space-x-4">
              <a href="https://x.com/trailsgamefi" className="text-gray-300 hover:text-white"><FaTwitter size={24} /></a>
              <a href="https://web.facebook.com/trailsnetwork" className="text-gray-300 hover:text-white"><FaFacebook size={24} /></a>
              <a href="https://www.youtube.com/@TrailsBuildersNetwork" className="text-gray-300 hover:text-white"><FaYoutube size={24} /></a>
              <a href="https://t.me/trailbuildersnetwork" className="text-gray-300 hover:text-white"><FaTelegram size={24} /></a>
              <a href="https://medium.com/@trailcoinnews" className="text-gray-300 hover:text-white"><FaMedium size={24} /></a>
              <a href="https://coinmarketcap.com/community/profile/trailweb3news/" className="text-gray-300 hover:text-white"><SiCoinmarketcap size={24} /></a>

            </div>
          </div>

        </div>
      </div>
    </footer>
  );
};

export default Footer;


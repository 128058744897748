import React from 'react'

const CryptoComSVG = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_6050_2160)">
    <path d="M19.4975 39.995L2 29.9975V10.0025L19.4975 0.00503559L36.9949 10.0025V29.9975L19.4975 39.995ZM3.2672 29.2748L19.4975 38.5462L35.7277 29.2714V10.7252L19.4975 1.4538L3.27058 10.7286V29.2748H3.2672Z" fill="white"></path>
    <path d="M26.1544 31.744H23.7551L20.8828 29.1342V27.7959L23.8565 24.9853V20.5353L27.7426 18.0259L32.1693 21.3383L26.1544 31.744Z" fill="white"></path>
    <path d="M19.4648 24.7177H16.2208L16.6601 20.5353L15.207 16.7879H23.7902L22.3709 20.5353L22.7764 24.7177H19.4648Z" fill="white"></path>
    <path d="M18.1783 29.1342L15.306 31.7775H12.873L6.82422 21.3383L11.2848 18.0594L15.2046 20.5353V24.9853L18.1783 27.7959V29.1342Z" fill="white"></path>
    <path d="M12.8396 9.05897H26.1198L27.708 15.7507H11.2852L12.8396 9.05897Z" fill="white"></path>
    </g>
    <defs>
    <clipPath id="clip0_6050_2160">
    <rect width="35" height="40" fill="white" transform="translate(2)"></rect>
    </clipPath>
    </defs>
    </svg>
  )
}

export default CryptoComSVG
export const tokenContractAddress =
"0xF1075300d3e57e069EB7ce2E9802Cba99a1c86AD";

export const todoContract =
"0xF1075300d3e57e069EB7ce2E9802Cba99a1c86AD";

export const tokendropcontract =
"0xF1075300d3e57e069EB7ce2E9802Cba99a1c86AD";

export const submitWalletcontracts =
"0x150AF75a06dA22AB472eea4Def46C7a151cFe0B0";
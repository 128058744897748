import React from 'react';
import { motion } from 'framer-motion';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, TooltipItem } from 'chart.js'; 
import { Doughnut } from 'react-chartjs-2';


ChartJS.register(ArcElement, Tooltip, Legend);

const TokenomicsSection = () => {
    const chartData = {
        labels: ['Initial Sale', 'Team & Advisors', 'Rewards & Incentives', 'Marketing & Partnerships', 'Development & Operations', 'Ecosystem Development Fund'],
        datasets: [
            {
                data: [40, 15, 20, 10, 10, 5], // Updated data
                backgroundColor: [
                    '#f59e0b', // Amber (Initial Sale)
                    '#3b82f6', // Blue (Team & Advisors)
                    '#10b981', // Green (Rewards & Incentives)
                    '#ef4444', // Red (Marketing & Partnerships)
                    '#8b5cf6', // Purple (Development & Operations)
                    '#db2777', // Pink (Ecosystem Development Fund) - You might want to adjust this color
                ],
                hoverOffset: 4
            }
        ]
    };
    
    const chartOptions = {
        plugins: {
            legend: {
                labels: {
                    color: 'white', 
                    font: {
                        size: 8, 
                        family: 'Arial, sans-serif' 
                    }
                }
            },
            tooltip: {
                callbacks: {
                label: (context: TooltipItem<'doughnut'>) => `${context.label}: ${context.formattedValue}%` 
                },
                bodyFont: {
                    size: 10 
                }
            }
        }
    };
    

  return (
    <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        className="space-y-6"
    >
        <h2 className="text-3xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r  from-white to-gray-500">Tokenomics</h2>

        {/* Trailcoin Overview */}
        <div className="bg-gradient-to-r from-gray-900 to-black p-6 rounded-lg shadow-xl flex flex-col md:flex-row items-center md:justify-between">
            <div>
                <h3 className="text-3xl font-extrabold text-transparent bg-clip-text text-transparent bg-gradient-to-r from-pink-500 to-blue-500">Trailcoin ($TRAIL)</h3>
                <p className="text-gray-300 mt-2">Empowering Your Journey to Web3 Freedom.</p>

                <div className="text-3xl font-extrabold text-transparent bg-clip-text text-transparent bg-gradient-to-r from-white to-blue-500 mt-4">
                    <strong className="text-3xl font-extrabold text-transparent bg-clip-text text-transparent bg-gradient-to-r from-pink-500 to-blue-500">Total Supply:</strong> 500,000,000
                </div>
            </div>
            
            <div className="w-48 h-48 mt-4 md:mt-0">
                <Doughnut data={chartData} options={chartOptions} /> 
            </div>
        </div>

        {/* Distribution & Sale Phases */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="bg-gradient-to-r from-gray-900 to-black p-6 rounded-lg shadow-xl">
                <h4 className="text-3xl font-extrabold text-transparent bg-clip-text text-transparent bg-gradient-to-r from-pink-500 to-blue-500">Distribution</h4>
                <ul className="text-gray-300 mt-4 space-y-3">
                    {chartData.labels.map((label, index) => (
                        <li key={index}>
                            <span className="font-medium">{label}:</span> {chartData.datasets[0].data[index]}%
                        </li>
                    ))}
                </ul>
            </div>

            <div className="bg-gradient-to-r from-gray-900 to-black p-6 rounded-lg shadow-xl">
                <h4 className="text-3xl font-extrabold text-transparent bg-clip-text text-transparent bg-gradient-to-r from-pink-500 to-blue-500">Sale Phases</h4>
                <div className="text-gray-300 mt-4 space-y-3">
                <div>
                        <strong className="text-purple-400">Round 1:</strong> <strong className="text-green-400">Seed Round</strong> - 25,000,000 TRAIL (10%) at $0.11 per TRAIL
                    </div>
                    <div>
                        <strong className="text-purple-400">Round 2:</strong> <strong className="text-yellow-400">Private Sale</strong> - 50,000,000 TRAIL (20%) at $0.22 per TRAIL
                    </div>
                    <div>
                        <strong className="text-purple-400">Round 3:</strong> <strong className="text-white">Public Sale</strong> - 75,000,000 TRAIL (30%) at $0.33 per TRAIL

                    </div>
                </div>
            </div>
        </div>
    </motion.div>
  );
};


export default TokenomicsSection;

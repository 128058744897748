import React from 'react';
import { motion } from "framer-motion";
import { FaStar } from 'react-icons/fa';

const BestBlockchainIcoGameBanner = () => {
  return (
    <motion.div 
      initial={{ opacity: 0, y: -20 }} 
      animate={{ opacity: 1, y: 0 }} 
      transition={{ duration: 0.8 }}
      className="bg-gradient-to-r from-yellow-300 to-yellow-500 py-6 flex justify-center items-center text-center"
    > 
      <div className="flex items-center justify-center space-x-3 max-w-lg mx-auto px-4 md:px-8"> {/* Centered content */}
        <div className="bg-white rounded-full p-2 shadow-md">
          <FaStar className="w-6 h-6 text-yellow-400" />
        </div>
        <span className="text-gray-800 font-extrabold text-lg md:text-2xl uppercase tracking-widest"> {/* Improved typography */}
          Best Blockchain ICO Game 2024
        </span>
        <div className="bg-white rounded-full p-2 shadow-md">
          <FaStar className="w-6 h-6 text-yellow-400" />
        </div>
      </div>
    </motion.div>
  );
};

export default BestBlockchainIcoGameBanner;

import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { FaFlagCheckered, FaRocket, FaHandshake, FaWrench, FaGlobeAmericas, FaSeedling, FaCode, FaUsers, FaGavel } from 'react-icons/fa'; // Add icons

const RoadmapComponents: React.FC = () => {
  const roadmap = [
    { 
        date: 'Q3 2023', 
        title: 'Trailcoin Whitepaper', 
        content: 'The inception of Trailcoin, a decentralized platform for sustainable trail stewardship.',
        icon: FaSeedling 
    },
    { 
        date: 'Q1 2024', 
        title: 'Smart Contract Development and Audit', 
        content: 'Expert blockchain developers meticulously crafted and audited secure smart contracts for the Trailcoin ecosystem.',
        icon: FaCode
    },
    { 
        date: 'Q2 2024', 
        title: 'Trail Builders Network Formation',
        content: 'Establishment of the Trail Builders Network, a vibrant community of trail enthusiasts and innovators.',
        icon: FaUsers
    },
    { 
        date: 'Q3 2024', 
        title: 'Beta Launch', 
        content: 'Initial beta release of the Trailcoin platform on a testnet for early community testing and feedback.',
        icon: FaRocket 
    },
    { 
        date: 'Q4 2024', 
        title: 'Mainnet Launch', 
        content: 'Official launch of the Trailcoin platform on the Ethereum mainnet, enabling real-world use and impact.',
        icon: FaFlagCheckered 
    },
    { 
        date: 'Q1 2025', 
        title: 'Decentralized Governance (DAO)', 
        content: 'Empowerment of $TRAIL holders through decentralized governance, enabling them to shape the platform\'s future.',
        icon: FaGavel
    },
    { 
        date: 'Q2 2025', 
        title: 'Strategic Partnerships & Integrations', 
        content: 'Formation of key partnerships with environmental organizations and integration with existing trail management platforms.',
        icon: FaHandshake 
    },
    { 
        date: 'Q3 2025 and Beyond', 
        title: 'Continuous Development & Global Expansion', 
        content: 'Ongoing enhancement of the Trailcoin platform with advanced features, gamification, and global expansion to new markets.',
        icon: FaGlobeAmericas
    },
  ];

  return (
    <section className="roadmap bg-gradient-to-br from-purple-900 to-indigo-900 py-20 md:py-32">
      <div className="container mx-auto px-4 md:px-8 lg:px-16 text-center">
        <motion.h2
          className="text-4xl md:text-5xl lg:text-6xl font-extrabold mb-12 text-white tracking-tight"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          Trailblazing the Future
        </motion.h2>
        <AnimatePresence>
          <VerticalTimeline>
            {roadmap.map((item, index) => (
              <VerticalTimelineElement
                key={index}
                contentStyle={{ background: '#1a202c', color: '#fff', boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}
                contentArrowStyle={{ borderRight: '7px solid #2196F3' }}
                date={item.date}
                dateClassName="text-gray-300"
                iconStyle={{ background: '#2196F3', color: '#fff', boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }}
                icon={<item.icon className="text-2xl" />} // Use the provided icon
              >
                <h3 className="vertical-timeline-element-title text-xl font-semibold">{item.title}</h3>
                <p className="vertical-timeline-element-subtitle">{item.content}</p>
              </VerticalTimelineElement>
            ))}
          </VerticalTimeline>
        </AnimatePresence>
      </div>
    </section>
  );
};

export default RoadmapComponents;

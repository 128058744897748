import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaBars, FaDiscord, FaFacebook, FaTelegram, FaTwitter, FaYoutube } from "react-icons/fa";
import { SiMedium, SiCoinmarketcap } from 'react-icons/si'; // Import new icons
import { ConnectWallet } from "@thirdweb-dev/react";
import { trailcoin } from "../images";

const Navbar: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <nav className="bg-gradient-to-r from-purple-900 via-indigo-900 to-black text-white sticky top-0 z-50 shadow-md transition-all duration-300">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-4 flex justify-between items-center">
        <Link
          to="/"
          className="text-2xl sm:text-3xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-green-400 to-teal-500 hover:opacity-90 transition-opacity flex justify-between items-center gap-2"
        >
          <img src={trailcoin} alt="Trail Builders Network" className="h-10 w-10 rounded-full" />
          Trailcoin
        </Link>

        {/* Desktop Navigation */}
        <div className="hidden md:flex space-x-6">
          <Link
            to="/"
            className="hover:text-purple-300 transition-colors duration-300 font-medium"
          >
            Home
          </Link>
          <Link
            to="/about"
            className="hover:text-purple-300 transition-colors duration-300 font-medium"
          >
            About
          </Link>
          <Link
            to="/features"
            className="hover:text-purple-300 transition-colors duration-300 font-medium"
          >
            Features
          </Link>
          <Link
            to="/tokenomics"
            className="hover:text-purple-300 transition-colors duration-300 font-medium"
          >
            Tokenomics
          </Link>
          <Link
            to="/roadmap"
            className="hover:text-purple-300 transition-colors duration-300 font-medium"
          >
            Roadmap
          </Link>
          
        </div>

        {/* Social Icons and Connect Wallet Button */}
        <div className="hidden md:flex items-center space-x-4">
        <a
            href="https://x.com/trailsgamefi"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Twitter"
            className="p-2 rounded-full hover:bg-blue-400/20 transition-colors duration-300"
          >
            <FaFacebook className="text-white text-lg" />
          </a>
          <a
            href="https://x.com/trailsgamefi"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Twitter"
            className="p-2 rounded-full hover:bg-blue-400/20 transition-colors duration-300"
          >
            <FaTwitter className="text-white text-lg" />
          </a>
          <a
            href="https://www.youtube.com/@TrailsBuildersNetwork"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Youtube"
            className="p-2 rounded-full hover:bg-purple-400/20 transition-colors duration-300"
          >
            <FaYoutube className="text-white text-lg" />
          </a>
          <a
            href="https://t.me/trailbuildersnetwork"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Telegram"
            className="p-2 rounded-full hover:bg-blue-500/20 transition-colors duration-300"
          >
            <FaTelegram className="text-white text-lg" />
          </a>

          {/* Add Medium and CoinMarketCap icons */}
          <a
            href="https://medium.com/@trailcoinnews" // Replace with your Medium handle
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Medium"
            className="p-2 rounded-full hover:bg-gray-200/20 transition-colors duration-300"
          >
            <SiMedium className="text-white text-lg" />
          </a>
          <a
            href="https://coinmarketcap.com/community/profile/trailweb3news/" // Replace with your coin name
            target="_blank"
            rel="noopener noreferrer"
            aria-label="CoinMarketCap"
            className="p-2 rounded-full hover:bg-gray-200/20 transition-colors duration-300"
          >
            <SiCoinmarketcap className="text-white text-lg" />
          </a>

          {/* <ConnectWallet className="ml-4 bg-gradient-to-r from-pink-500 to-blue-500 text-white font-bold py-2 px-4 rounded-md shadow-md hover:opacity-90 transition-opacity" /> */}
        </div>

        {/* Mobile Menu Button */}
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="md:hidden text-white focus:outline-none"
        >
          <FaBars className="text-2xl" />
        </button>
      </div>

      {/* Mobile Menu */}
      {isOpen && (
        <div className="md:hidden bg-gray-800/80 backdrop-blur-md p-4 mt-2 flex flex-col items-center space-y-4 rounded-md shadow-md transition-all duration-300">
          <Link
            to="/about"
            className="block py-2 hover:text-purple-400 transition-colors duration-300 font-medium w-full text-center"
            onClick={() => setIsOpen(false)} // Close menu on link click
          >
            About
          </Link>
          <Link
            to="/features"
            className="block py-2 hover:text-purple-400 transition-colors duration-300 font-medium w-full text-center"
            onClick={() => setIsOpen(false)}
          >
            Features
          </Link>
          <Link
            to="/tokenomics"
            className="block py-2 hover:text-purple-400 transition-colors duration-300 font-medium w-full text-center"
            onClick={() => setIsOpen(false)}
          >
            Tokenomics
          </Link>
          <Link
            to="/roadmap"
            className="block py-2 hover:text-purple-400 transition-colors duration-300 font-medium w-full text-center"
            onClick={() => setIsOpen(false)}
          >
            Roadmap
          </Link>

          {/* Social Icons on Mobile with Labels */}
          <div className="flex items-center space-x-4 mt-2">
            <a
              href="https://x.com/trailsgamefi"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Twitter"
              className="p-2 rounded-full hover:bg-blue-400/20 transition-colors duration-300"
            >
              <FaTwitter className="text-white" />
            </a>
            <a
              href="https://web.facebook.com/trailsnetwork"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Twitter"
              className="p-2 rounded-full hover:bg-blue-400/20 transition-colors duration-300"
            >
              <FaFacebook className="text-white" />
            </a>
            <a
              href="https://www.youtube.com/@TrailsBuildersNetwork"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Youtube"
              className="p-2 rounded-full hover:bg-purple-400/20 transition-colors duration-300"
            >
              <FaYoutube className="text-white" />
            </a>
            <a
              href="https://t.me/trailbuildersnetwork"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Telegram"
              className="p-2 rounded-full hover:bg-blue-500/20 transition-colors duration-300"
            >
              <FaTelegram className="text-white" />
            </a>

            {/* Add Medium and CoinMarketCap icons to mobile menu */}
            <a
              href="https://medium.com/@trailcoinnews" // Replace with your Medium handle
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Medium"
              className="p-2 rounded-full hover:bg-gray-200/20 transition-colors duration-300"
            >
              <SiMedium className="text-white" />
            </a>
            <a
              href="https://coinmarketcap.com/community/profile/trailweb3news/" // Replace with your coin name
            target="_blank"
            rel="noopener noreferrer"
            aria-label="CoinMarketCap"
            className="p-2 rounded-full hover:bg-gray-200/20 transition-colors duration-300"
            >
            <SiCoinmarketcap className="text-white" />
            </a>

          </div>

          <button className="bg-gradient-to-r from-pink-500 to-blue-500 text-white font-bold py-2 px-4 rounded-md shadow-md mt-4 hover:opacity-90 transition-opacity w-full">
            Explore Now
          </button>
        </div>
      )}
    </nav>
  );
};

export default Navbar;


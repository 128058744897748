// ExchangeComponents.js
import React from 'react';
import BinanceSVG from '../svgs/BinanceIcon';
import OKXSvg from '../svgs/OKXIcon';
import CryptoComSVG from '../svgs/CryptoComSVG';
import BybitSVG from '../svgs/BybitSVG';
import BingxSVG from '../svgs/BingxSVG';
// import CryptoComSVG from './CryptoComSVG';
// import BybitSVG from './BybitSVG';
// import BingxSVG from './BingxSVG';
// import HTXSvg from './HTXSvg';
// import KucoinSVG from './KucoinSVG';

const ExchangeComponents = () => {
  const exchanges = [
    { component: <BinanceSVG />, className: 'is-binance' },
    { component: <OKXSvg />, className: 'is-okx' },
    { component: <CryptoComSVG />, className: 'is-crypto_com' },
    { component: <BybitSVG />, className: 'is-bybit' },
    { component: <BingxSVG />, className: 'is-bingx' },
    // { component: <HTXSvg />, className: 'is-htx' },
    // { component: <KucoinSVG />, className: 'is-kucoin' },
  ];

  return (
    <div className="ln-content-exchanges">
      {exchanges.map((exchange, index) => (
        <div key={index} className="ln-content-exchange">
          <div className={`exchange-image ${exchange.className}`}>
            {exchange.component}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ExchangeComponents;

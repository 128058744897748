import React from 'react';

const Contact: React.FC = () => {
    return (
        <div className="p-10">
            <h1 className="text-4xl font-bold mb-4">Contact Us</h1>
            <p className="text-lg mb-4">We'd love to hear from you! Reach out to us with any questions, comments, or inquiries.</p>
            <p className="text-lg mb-4">Email: contact@trailsbuildersnetwork.com</p>
        </div>
    );
};

export default Contact;

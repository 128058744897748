import React from 'react';
import { FaFile, FaFileAlt } from 'react-icons/fa';

const VideoAndLinks = () => {
  return (
    <div className="py-32 px-8 md:px-16 lg:px-32 bg-gradient-to-b from-black to-blue-900 text-white">
      <div className="text-3xl md:text-4xl lg:text-5xl font-extrabold mb-8 text-center">
        WATCH THE VIDEO
      </div>
      <div className="video-section container mx-auto max-w-7xl p-6 bg-gradient-to-b from-black to-[#495b8d00] text-white mb-8">
        <div className="video-frame relative pb-[56.25%] overflow-hidden rounded-lg border-4 border-purple-400 shadow-md">
          <iframe
            className="absolute top-0 left-0 w-full h-full"
            src="https://www.youtube.com/embed/VdPsLC-4nBo"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </div>
        <h2 className="mt-8 text-3xl md:text-4xl lg:text-5xl font-extrabold mb-8 text-center">Trailcoin Whitepaper
        </h2>  
        <p className='text-white text-center'>The inception of Trailcoin, a decentralized platform for sustainable trail stewardship.</p>   

      <div className="document-links flex justify-center gap-8 mt-4">
        <a
          href="https://docs.google.com/document/d/14odxYsiFwPjExpXmKYkvXdVt3iB8g0UIY-wF8C_kYoI/edit?usp=sharing"
          target="_blank"
          className="flex flex-col items-center justify-center w-64 h-40 border-2 border-blue-500 rounded-lg bg-purple-800 text-white hover:bg-gray-700 transition duration-300 shadow-lg"
        >
          <FaFile className="text-6xl mb-2 text-gray-300" />
          <span className="font-semibold items-center justify-center text-sm text-black-500">ONE PAGER</span>
        </a>
        <a
          href="https://docs.google.com/document/d/1wKgoEciu3Pb7kU8CE8L_sqH-jc7IMh54f4EN7hJJ2ZI/edit?usp=sharing"
          target="_blank"
          className="flex flex-col items-center justify-center w-64 h-40 border-2 border-blue-500 rounded-lg bg-purple-800 text-white hover:bg-gray-700 transition duration-300 shadow-lg"
        >
          <FaFileAlt className="text-6xl mb-2 text-gray-300" />
          <span className="font-semibold items-center justify-center text-center text-sm text-black-500">WHITE PAPER</span>
        </a>
      </div>
    </div>
    </div>
  );
};

export default VideoAndLinks;

import React from 'react';

interface ProductFeatureProps {
  title: string;
  description: string;
}

const ProductFeature: React.FC<ProductFeatureProps> = ({ title, description }) => {
  return (
    <div className="bg-gradient-to-r from-gray-800 to-gray-900 p-6 rounded-lg shadow-xl flex flex-col items-start"> 
      <h3 className="text-3xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-purple-500">{title}</h3>
      <p className="text-gray-300 text-base">{description}</p>
    </div>
  );
};

export default ProductFeature;

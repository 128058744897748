import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { FaLeaf, FaUsers, FaMapMarkedAlt, FaRegCalendarAlt } from 'react-icons/fa';
import Timeline from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

const AboutSection = () => {
  const [selectedTab, setSelectedTab] = useState('introduction');

  const milestones = [
    {
      date: "Q4 2023",
      title: "Trailcoin Whitepaper",
      description: "We conceptualized Trailcoin, a decentralized platform to revolutionize trail stewardship."
    },
    {
      date: "Q1 2024",
      title: "Smart Contract Development",
      description: "Our expert blockchain developers meticulously crafted secure and transparent smart contracts."
    },
    {
      date: "Q2 2024",
      title: "Trail Builders Network Formation",
      description: "We forged a vibrant community of trail enthusiasts and innovators dedicated to our mission."
    },
    // Add more milestones here
  ];

  return (
    <section className="about py-32 px-8 md:px-16 lg:px-32 bg-gray-900 text-white">
      <div className="container mx-auto max-w-5xl">
        <h2 className="text-3xl md:text-4xl lg:text-5xl font-extrabold mb-8 text-center">
          Trailcoin: Empowering Your Journey to Web3 Freedom
        </h2>

        <p className="text-lg md:text-xl mb-12 leading-relaxed text-center">
          Trailcoin isn't simply a digital currency; it's a catalyst for change, igniting a movement towards decentralization, financial empowerment, and a sustainable future.
        </p>

      {/* Tabs */}
<div className="flex flex-col md:flex-row justify-center mb-8 space-y-4 md:space-y-0 md:space-x-4">
  <button
    className={`px-6 py-2 rounded-md transition-colors duration-300 ${
      selectedTab === 'introduction' ? 'bg-purple-700 text-white' : 'bg-gray-700 hover:bg-gray-600'
    }`}
    onClick={() => setSelectedTab('introduction')}
  >
    Introduction
  </button>
  <button
    className={`px-6 py-2 rounded-md transition-colors duration-300 ${
      selectedTab === 'vision' ? 'bg-purple-700 text-white' : 'bg-gray-700 hover:bg-gray-600'
    }`}
    onClick={() => setSelectedTab('vision')}
  >
    Vision
  </button>
  <button
    className={`px-6 py-2 rounded-md transition-colors duration-300 ${
      selectedTab === 'mission' ? 'bg-purple-700 text-white' : 'bg-gray-700 hover:bg-gray-600'
    }`}
    onClick={() => setSelectedTab('mission')}
  >
    Mission
  </button>
  <button
    className={`px-6 py-2 rounded-md transition-colors duration-300 ${
      selectedTab === 'network' ? 'bg-purple-700 text-white' : 'bg-gray-700 hover:bg-gray-600'
    }`}
    onClick={() => setSelectedTab('network')}
  >
    Network
  </button>
  <button
    className={`px-6 py-2 rounded-md transition-colors duration-300 ${
      selectedTab === 'use-cases' ? 'bg-purple-700 text-white' : 'bg-gray-700 hover:bg-gray-600'
    }`}
    onClick={() => setSelectedTab('use-cases')}
  >
    Use Cases
  </button>
</div>


        {/* Tab Content */}
        <motion.div
          key={selectedTab} 
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.5 }}
          className="bg-gray-800 rounded-lg shadow-md p-8 text-white" 
        >
          {selectedTab === 'introduction' && (
            <>
            <div className="mb-8"> {/* Added margin for spacing */}
              <h3 className="text-3xl font-extrabold mb-4 text-transparent bg-clip-text bg-gradient-to-r from-purple-500 to-pink-500"> 
                Introduction 
              </h3> 
              <p className="text-gray-300 leading-relaxed text-lg"> {/* Increased font size for better readability */}
              Trailcoin isn't just a digital currency; it's a catalyst for change. Inspired by the spirit of exploration and the transformative power of Web3, Trailcoin is forging a vibrant ecosystem where outdoor enthusiasts, crypto enthusiasts, and aspiring wealth builders unite to create a sustainable future.
</p>
            </div>
          </>
          )}

          {selectedTab === 'vision' && (
           <>
           <div className="mb-8"> 
             <h3 className="text-3xl font-extrabold mb-4 text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-green-500"> 
               Vision 
             </h3> 
             <p className="text-gray-300 leading-relaxed text-lg">
               Trailcoin envisions a world where every individual possesses the tools and knowledge to navigate the exciting yet complex realm of Web3 and the decentralized economy. We aim to empower you on your path to financial freedom and a fulfilling lifestyle.
             </p>
           </div>
         </>
          )}

          {selectedTab === 'mission' && (
           <>
           <div className="mb-8 p-6 bg-gray-800 rounded-lg shadow-md"> 
             <h3 className="text-3xl font-extrabold mb-4 text-transparent bg-clip-text bg-gradient-to-r from-yellow-500 to-orange-500">
               Mission
             </h3> 
             <p className="text-gray-300 leading-relaxed text-lg mb-4">
               Trailcoin's mission is multifaceted, driven by a commitment to:
             </p>
             <ul className="list-disc list-inside text-gray-300 text-lg space-y-2">
               <li>
                 <span className="font-semibold">Accessibility & Inclusivity:</span> Making Web3 and blockchain technology accessible to everyone, regardless of their background or technical expertise.
               </li>
               <li>
                 <span className="font-semibold">Sustainable Growth:</span>  Fostering the responsible and sustainable growth of the Web3 ecosystem, ensuring its long-term viability.
               </li>
               <li>
                 <span className="font-semibold">Collaboration & Innovation:</span> Encouraging collaboration and innovation within the Trailcoin community and the broader Web3 space.
               </li>
               <li>
                 <span className="font-semibold">Empowerment & Economic Opportunity:</span> Providing individuals with the tools and opportunities to achieve financial independence and create a fulfilling lifestyle through Web3.
               </li>
               <li>
                 <span className="font-semibold">Positive Impact:</span>  Utilizing Trailcoin and Web3 technologies to create a positive impact on the environment, society, and the global economy.
               </li>
             </ul>
           </div>
         </>
         
          )}

          {selectedTab === 'use-cases' && (
           <>
           <div className="mb-8 p-6 bg-gray-800 rounded-lg shadow-md"> 
             <h3 className="text-3xl font-extrabold mb-4 text-transparent bg-clip-text bg-gradient-to-r from-teal-400 to-blue-500">
               Use Cases
             </h3> 
             <p className="text-gray-300 leading-relaxed text-lg mb-4">
               Trailcoin's utility extends across a wide array of applications:
             </p>
             <ul className="list-disc list-inside text-gray-300 text-lg space-y-2"> 
               <li>
                 <span className="font-semibold">Rewarding Trail Stewardship:</span> Incentivize contributions to trail development and maintenance, promoting outdoor recreation and environmental sustainability
               </li>
               <li>
                 <span className="font-semibold">Fostering Learning & Engagement:</span> Reward users for completing educational modules, participating in Web3 activities, and creating valuable content
               </li>
               <li>
                 <span className="font-semibold">Enabling Seamless Transactions:</span> Facilitate micropayments and tipping within the Trailcoin ecosystem and beyond, opening new possibilities for the creator economy and beyond
               </li>
               <li>
                 <span className="font-semibold">Unlocking Exclusive Benefits:</span> Access premium features and services within the community through active participation and contribution.
               </li>
               <li>
                 <span className="font-semibold">Driving Positive Change:</span>  Support environmental and social initiatives through donations and participation in impactful projects. 
               </li>
             </ul>
           </div>
         </>
          )}


{selectedTab === 'network' && (
              <>
                <div className="mb-8 p-6 bg-gray-800 rounded-lg shadow-md"> 
                  <h3 className="text-3xl font-extrabold mb-4 text-transparent bg-clip-text bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500">
                    The Trail Builders Network
                  </h3> 
                  <p className="text-gray-300 leading-relaxed text-lg mb-4">
                    The Trail Builders Network is a vibrant community of trail enthusiasts, environmentalists, and blockchain advocates who are passionate about preserving and enhancing natural trails. We believe in the power of collaboration and decentralized governance to create a more sustainable future for our planet.
                  </p>
                  <p className="text-gray-300 leading-relaxed text-lg mb-4">
                    As a member of the Trail Builders Network, you will have the opportunity to:
                  </p>
                  <ul className="list-disc list-inside text-gray-300 text-lg space-y-2">
                    <li>
                      <span className="font-semibold">Connect & Collaborate:</span> Engage with like-minded individuals from around the world, sharing ideas and experiences.
                    </li>
                    <li>
                      <span className="font-semibold">Shape the Future:</span> Participate in community-led trail projects and initiatives, making a tangible impact on the trails you love
                    </li>
                    <li>
                      <span className="font-semibold">Share Your Expertise:</span> Contribute your unique skills and knowledge to help improve the ecosystem and empower others
                    </li>
                    <li>
                      <span className="font-semibold">Earn Rewards:</span> Receive recognition and rewards for your valuable contributions to trail stewardship and community building
                    </li>
                  </ul>
                </div>
              </>            
          )}
        </motion.div>
      </div>
    </section>
  );
};

export default AboutSection;

// src/components/TokenSaleRounds.tsx
import React from "react";

const TokenSaleRounds: React.FC = () => {
    const rounds = [
        {
          round: "Seed Sale",
          price: "$0.11", // Increased from $0.01
          cap: "25,000,000 TRAIL (10% of total supply)", 
          active: false, 
        },
        {
          round: "Private Sale",
          price: "$0.22", // Increased from $0.02
          cap: "50,000,000 TRAIL (20% of total supply)", 
          active: false, 
        },
        {
          round: "Public Sale",
          price: "$0.33", // Increased from $0.03
          cap: "75,000,000 TRAIL (30% of total supply)", 
          active: false, 
        },
      ];

  return (
    <div className="flex flex-col items-center justify-center bg-gradient-to-b from-black to-blue-900 text-white p-8 py-32 px-8 md:px-16 lg:px-3"> 
    <h1 className="text-4xl md:text-5xl lg:text-6xl font-extrabold mb-12 text-center text-white tracking-tight">Token Sale Rounds</h1> 
    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
      {rounds.map((round, index) => (
        <div
          key={index}
          className={`p-8 border rounded-lg shadow-lg transition-transform duration-300 transform ${
            round.active
              ? "border-blue-400 hover:scale-105" 
              : "border-gray-700 opacity-70 hover:opacity-100"
          } bg-gradient-to-br from-gray-800 to-blue-950`} 
        >
          <h2 className="text-2xl font-semibold text-blue-300 mb-4 uppercase tracking-wide">{round.round}</h2>
          <p className="text-3xl font-bold mb-2 text-blue-200">
            {round.price} <span className="text-sm font-normal">per token</span>
          </p>
          <p className="text-lg font-medium mb-6">Round cap: {round.cap}</p>
          <button 
            className={`btn btn-primary ${round.active ? "" : "opacity-50 cursor-not-allowed"}`}
            disabled={!round.active} 
          >
            BUY TOKENS
          </button>
        </div>
      ))}
    </div>
  </div>
  );
};

export default TokenSaleRounds;
import React from 'react';
import AboutComponent from '../components/About';
import FeaturesComponents from '../components/Features';
import RoadmapComponents from '../components/RoadmapSection';
import TokenomicsComponents from '../components/Tokenomics';
import UseCasesSection from '../components/UseCasesSection';
import SecuritySection from '../components/SecuritySection';
import Footer from '../components/Footer';
import HeroSection from '../components/Hero';
import TokenDetails from '../components/TokenDetails';
import TokenSaleRounds from '../components/TokenSaleRounds';
import VideoAndLinks from '../components/VideoAndLinks';
import BestBlockchainIcoGameBanner from '../components/BestBlockchainIcoGameBanner';

const Home: React.FC = () => {
  return (
    <>
    <div className="bg-gradient-to-r from-purple-800 via-pink-600 to-blue-500 mx-auto px-3">
      <HeroSection />
      <BestBlockchainIcoGameBanner/>
      <VideoAndLinks />
      <AboutComponent />
      <FeaturesComponents />
      <TokenSaleRounds />
      <TokenomicsComponents />
      <RoadmapComponents />
      <UseCasesSection />
      <SecuritySection />
    </div>
     <Footer />
     </>
  );
};

export default Home;

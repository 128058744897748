import { ethers } from 'ethers';
import React, { useEffect, useState } from 'react'


interface CoinInfo {
  address: string;
  chainId: number;
  priceUsd: number;
  priceUsd24hAgo: number;
  marketCapUsd: number;
  totalReserveUsd: number;
}

const PriceFeedV1 = () => {
  const EXPLOITATION_FEES = 0.90;
  const CHAINLINK_PRICE_FEED_ADDRESS = '0xB97Ad0E74fa7d920791E90258A6E2085088b4320';

  const aggregatorV3InterfaceABI = [
    {
      inputs: [],
      name: "decimals",
      outputs: [{ internalType: "uint8", name: "", type: "uint8" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "description",
      outputs: [{ internalType: "string", name: "", type: "string" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint80", name: "_roundId", type: "uint80" }],
      name: "getRoundData",
      outputs: [
        { internalType: "uint80", name: "roundId", type: "uint80" },
        { internalType: "int256", name: "answer", type: "int256" },
        { internalType: "uint256", name: "startedAt", type: "uint256" },
        { internalType: "uint256", name: "updatedAt", type: "uint256" },
        { internalType: "uint80", name: "answeredInRound", type: "uint80" },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "latestRoundData",
      outputs: [
        { internalType: "uint80", name: "roundId", type: "uint80" },
        { internalType: "int256", name: "answer", type: "int256" },
        { internalType: "uint256", name: "startedAt", type: "uint256" },
        { internalType: "uint256", name: "updatedAt", type: "uint256" },
        { internalType: "uint80", name: "answeredInRound", type: "uint80" },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "version",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
  ];

  const [coinData, setCoinData] = useState<CoinInfo[]>([]);
  const [goldPrice, setGoldPrice] = useState<number | null>(null);
  const [genPrice, setGenPrice] = useState<number | null>(null);

  useEffect(() => {
    async function fetchCoinData() {
      const url = "https://api.coinbrain.com/public/coin-info";
      const payload = {
        "56": ["0x0042e6b7db042cf384c60be5fa347de8a10dd9b3"], // Replace with actual token addresses
      };

      try {
        const response = await fetch(url, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(payload),
        });

        if (response.ok) {
          const data = await response.json();
          setCoinData(data);
        } else {
          console.error("Failed to fetch coin data.");
        }
      } catch (error) {
        console.error("An error occurred:", error);
      }
    }

    fetchCoinData();
  }, []);

  useEffect(() => {
    async function fetchGoldPrice() {
      try {
        const provider = new ethers.providers.JsonRpcProvider("https://bsc-dataseed.binance.org/");
        const priceFeed = new ethers.Contract(CHAINLINK_PRICE_FEED_ADDRESS, aggregatorV3InterfaceABI, provider);
        const latestRoundData = await priceFeed.latestRoundData();
        const decimals = await priceFeed.decimals();
        const price = Number(latestRoundData.answer) / 10 ** decimals;
        setGoldPrice(price);
      } catch (error) {
        console.error('Error fetching gold price:', error);
      }
    }
    fetchGoldPrice();
  }, []);
  
  useEffect(() => {
    if (goldPrice !== null) {
      const genPriceCalculated = goldPrice - EXPLOITATION_FEES;
      setGenPrice(genPriceCalculated);
    }
  }, [goldPrice]);

  const formatMoney = (value: number) => {
    return value?.toLocaleString("en-US", { style: "currency", currency: "USD" }) || "N/A";
  };

  return (       
              <div>
                <p className='font-bold'>Price per TrailCoin: {genPrice ? formatMoney(genPrice) : "$0.00"}</p>
              </div>
             
        
  );
};

export default PriceFeedV1


